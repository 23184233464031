import '../css/Navbar.css'

import { Link, useMatch, useResolvedPath } from "react-router-dom"
import React, { useState } from 'react'


export default function Navbar() {
    const [isHamburgerExpanded, setIsHamburgerExpanded] = useState(false)

    return (
        <React.Fragment>
            <nav className="nav sticky">
                <Link to="/" className="site-title">Ear Trainer</Link>
                {/* <Link to='/'>
                    <img className='nav-logo' src='./klubby.png' />
                </Link> */}

                <button className="hamburger">
                    {/* icon from heroicons.com */}
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="white"
                    onClick={() => {
                        setIsHamburgerExpanded(!isHamburgerExpanded)
                    }}
                    >
                        <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clipRul="evenodd"/>
                    </svg>
                </button>

                <ul className={isHamburgerExpanded ? "expanded" : null}>
                    <CustomLink to='/'>Home</CustomLink>
                    <CustomLink to='/interval'>Interval Game</CustomLink>
                </ul>
            </nav>
            <div className="nav-padding" />
        </React.Fragment>
    )
}

function CustomLink({to, children, ...props}){
    //get current full resolved path
    const resolvedPath = useResolvedPath(to)
    //detect if page is active
    const isActive = useMatch({ path: resolvedPath.pathname, end: true})

    return (
        <li className={isActive ? "active" : null}>
            <Link to={to}>{children}</Link>
        </li>
    )
}