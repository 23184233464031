import React from 'react';

import { playHarmonicUnison, playHarmonicThird, playHarmonicFourth, playHarmonicFifth, playHarmonicSixth, playHarmonicMinorSeventh, playHarmonicOctave, playNote } from '../../../lib/Sounds';
import styles from '../../../css/components/pages/IntervalGame/FeedbackIntervals.module.css';

function FeedbackIntervals(props) {
  const intervals = {
    // 'unison': playHarmonicUnison,
    'major_third': playHarmonicThird,
    'perfect_fourth': playHarmonicFourth,
    'perfect_fifth': playHarmonicFifth,
    'major_sixth': playHarmonicSixth,
    'minor_seventh': playHarmonicMinorSeventh,
    'octave': playHarmonicOctave
  };

  const handleButtonClick = async (interval) => {
      // play the interval starting from props.firstNote
      await intervals[interval](props.firstNote);
  };

  return (
    <div>
      <button onClick={() => playNote(props.firstNote,props.selectedInstrument)}>Note 1</button><button onClick={() => playNote(props.secondNote,props.selectedInstrument)}>Note 2</button>
      <br />
      <label>Intervals</label>
      {Object.keys(intervals).map((interval) => (
        <button className={styles.feedback_botton} key={interval} onClick={() => handleButtonClick(interval)}>
          {interval}
        </button>
      ))}
    </div>
  );
}

export default FeedbackIntervals;