import React from 'react';
import { playAscendingUnison, playAscendingThird, playAscendingFourth, playAscendingFifth, playAscendingSixth, playAscendingMinorSeventh, playAscendingOctave, playDescendingUnison, playDescendingThird, playDescendingFourth, playDescendingFifth, playDescendingSixth, playDescendingOctave } from '../../../lib/Sounds';

import styles from '../../../css/components/pages/IntervalGame/FeedbackIntervals.module.css';

function FeedbackIntervals(props) {
  const ascending_intervals = {
    'unison': playAscendingUnison,
    'major_third': playAscendingThird,
    'perfect_fourth': playAscendingFourth,
    'perfect_fifth': playAscendingFifth,
    'major_sixth': playAscendingSixth,
    'minor_seventh': playAscendingMinorSeventh,
    'octave': playAscendingOctave
  };

  const descending_intervals = {
    'unison': playDescendingUnison,
    'major_third': playDescendingThird,
    'perfect_fourth': playDescendingFourth,
    'perfect_fifth': playDescendingFifth,
    'major_sixth': playDescendingSixth,
    // 'minor_seventh': playDescendingSeventh,
    'octave': playDescendingOctave
  };

  const handleButtonClick = async (interval,direction) => {
    if (direction === 'descending') {
      // play the interval starting from props.firstNote
      await descending_intervals[interval](props.firstNote, props.delay);
    }
    else if (direction === 'ascending') {
      // play the interval starting from props.firstNote
      await ascending_intervals[interval](props.firstNote, props.delay);
    }
  };

  return (
    <div>
      <label>Ascending</label>
      {Object.keys(ascending_intervals).map((interval) => (
        <button className={styles.feedback_botton} key={interval} onClick={() => handleButtonClick(interval,'ascending')}>
          {interval}
        </button>
      ))}
      <br />
      <label>Descending</label>
      {Object.keys(descending_intervals).map((interval) => (
        <button className={styles.feedback_botton} key={interval} onClick={() => handleButtonClick(interval,'descending')}>
          {interval}
        </button>
      ))}
    </div>
  );
}

export default FeedbackIntervals;