import logo from './logo.svg';
import './App.css';

import { Routes, Route } from "react-router-dom";

import Navbar from './components/Navbar';
import Home from './pages/Home';
import IntervalGame from './pages/IntervalGame';
import HarmonicIntervalGame from './pages/HarmonicIntervalGame';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/interval" element={<IntervalGame />} />
        <Route path="/harmonic-interval" element={<HarmonicIntervalGame />} />
      </Routes>
    </div>
  );
}

export default App;
