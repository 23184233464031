import React from "react";

const UnplayedView = (props) => {
    let gameState = props.gameState;

    return (
        <React.Fragment>
            { gameState !== 'not_played' ?
                <React.Fragment>
                    <button className='default-button' onClick={props.handleReplay}>Hear Again</button>
                    <br/>
                </React.Fragment>
                : null
            }
            <button className='play-button' onClick={props.handlePlayButton}>Play</button>
        </React.Fragment>
    )
}

export default UnplayedView;