import React from "react";

import Select from 'react-select'

import {getNoteList} from '../../../lib/Sounds'

import styles from '../../../css/components/pages/IntervalGame/SettingsController.module.css';

const note_name_list = getNoteList()


let option_list = []
for(let i in note_name_list){
    option_list.push({value: parseInt(i),label: note_name_list[i]})
}


// set up basic react component
const SettingsController = (props) => {
    let selectedIntervals = props.selectedIntervals;
    let gameState = props.gameState;
    let delay = props.delay;

    const instrument_select_options = []
    for(let i in props.instrument_list){
        instrument_select_options.push({value: props.instrument_list[i],label: props.instrument_list[i]})
    }

    function handleClickIntervalCheckbox(event){
        //make copy of selected intervals obj
        let copy_selectedIntervals = {...selectedIntervals}
        //update state
        copy_selectedIntervals[event.target.value] = event.target.checked
        props.setSelectedIntervals(copy_selectedIntervals)
    }

    return (
        <div className={styles.settings_container}>
            <div className={styles.interval_checkboxes_container}>
                <div className={styles.header_row}> 
                    <label className={styles.row_item}>Intervals</label>
                    <label className={styles.row_item}>Ascending</label>
                    <label className={styles.row_item}>Descending</label>
                </div>
                <div className={styles.interval_row}>
                    <label className={styles.row_item}>Unison</label>
                    <input className={styles.row_item} type="checkbox" onChange={handleClickIntervalCheckbox} value="ascending_unison" checked={selectedIntervals['ascending_unison']} disabled={(gameState === 'played')} />
                    <input className={styles.row_item} type="checkbox" onChange={handleClickIntervalCheckbox} value="descending_unison" checked={selectedIntervals['descending_unison']} disabled={(gameState === 'played')} />
                </div>
                <div className={styles.interval_row}>
                    <label className={styles.row_item}>Major Third</label>
                    <input className={styles.row_item} type="checkbox" onChange={handleClickIntervalCheckbox} value="ascending_major_third" checked={selectedIntervals['ascending_major_third']} disabled={(gameState === 'played')} />
                    <input className={styles.row_item} type="checkbox" onChange={handleClickIntervalCheckbox} value="descending_major_third" checked={selectedIntervals['descending_major_third']} disabled={(gameState === 'played')} />
                </div>
                <div className={styles.interval_row}>
                    <label className={styles.row_item}>Perfect Fourth</label>
                    <input className={styles.row_item} type="checkbox" onChange={handleClickIntervalCheckbox} value="ascending_perfect_fourth" checked={selectedIntervals['ascending_perfect_fourth']} disabled={(gameState === 'played')} />
                    <input className={styles.row_item} type="checkbox" onChange={handleClickIntervalCheckbox} value="descending_perfect_fourth" checked={selectedIntervals['descending_perfect_fourth']} disabled={(gameState === 'played')} />
                </div>
                <div className={styles.interval_row}>
                    <label className={styles.row_item}>Perfect Fifth</label>
                    <input className={styles.row_item} type="checkbox" onChange={handleClickIntervalCheckbox} value="ascending_perfect_fifth" checked={selectedIntervals['ascending_perfect_fifth']} disabled={(gameState === 'played')} />
                    <input className={styles.row_item} type="checkbox" onChange={handleClickIntervalCheckbox} value="descending_perfect_fifth" checked={selectedIntervals['descending_perfect_fifth']} disabled={(gameState === 'played')} />
                </div>
                <div className={styles.interval_row}>
                    <label className={styles.row_item}>Major Sixth</label>
                    <input className={styles.row_item} type="checkbox" onChange={handleClickIntervalCheckbox} value="ascending_major_sixth" checked={selectedIntervals['ascending_major_sixth']} disabled={(gameState === 'played')} />
                    <input className={styles.row_item} type="checkbox" onChange={handleClickIntervalCheckbox} value="descending_major_sixth" checked={selectedIntervals['descending_major_sixth']} disabled={(gameState === 'played')} />
                </div>
                <div className={styles.interval_row}>
                    <label className={styles.row_item}>Minor Seventh</label>
                    <input className={styles.row_item} type="checkbox" onChange={handleClickIntervalCheckbox} value="ascending_minor_seventh" checked={selectedIntervals['ascending_minor_seventh']} disabled={(gameState === 'played')} />
                    <input className={styles.row_item} type="checkbox" onChange={handleClickIntervalCheckbox} value="descending_minor_seventh" checked={selectedIntervals['descending_minor_seventh']} disabled={(gameState === 'played')} />
                </div>
                <div className={styles.interval_row}>
                    <label className={styles.row_item}>Octave</label>
                    <input className={styles.row_item} type="checkbox" onChange={handleClickIntervalCheckbox} value="ascending_octave" checked={selectedIntervals['ascending_octave']} disabled={(gameState === 'played')} />
                    <input className={styles.row_item} type="checkbox" onChange={handleClickIntervalCheckbox} value="descending_octave" checked={selectedIntervals['descending_octave']} disabled={(gameState === 'played')} />
                </div>
            </div>

            <br/>
            <br/>

            {/* <div className="ascend_descend_container">
                <label>Ascending/Descending</label>
                <br/>
                <input type="radio" id="ascending" name="ascend_descend" />
                <label for="ascending">Ascending</label>
                <br/>
                <input type="radio" id="descending" name="ascend_descend" />
                <label for="descending">Descending</label>
                <br/>
                <input type="radio" id="both" name="ascend_descend" />
                <label for="both">Both</label>
            </div>                     */}

            {/* <input type="range" className="min-note-range-input"></input> */}
            {/* <input type="range" className="max-note-range-input"></input> */}
            <div className="note-select-container">
                <div>
                    <label>Min Note</label>
                    <Select className="min-note-select" options={option_list} defaultValue={option_list[0]} onChange={(selected_option) => { props.setMinNote(selected_option.value) }}/>
                </div>
                <div>
                    <label>Max Note</label>
                    <Select className="max-note-select" options={option_list} defaultValue={option_list[option_list.length-1]} onChange={(selected_option) => { props.setMaxNote(selected_option.value) }}/>
                </div>
            </div>

            <br/>
            {/* <br/> */}

            <label>Instrument</label>
            <Select className="instrument-select" options={instrument_select_options} defaultValue={instrument_select_options[0]} onChange={(selected_option) => { props.setSelectedInstrument(selected_option.value) }}/>

            <label>Delay: </label>
            <input className="delay-input" type="number"  step="0.1" defaultValue={delay} onChange={(event) => { props.setDelay(event.target.value) }}/>
        </div>
    )
}

export default SettingsController;