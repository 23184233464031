// import c2 from '../sounds/c2.wav'
// import c_S2 from '../sounds/c_S2.wav'
// import d2 from '../sounds/d2.wav'
// import d_S2 from '../sounds/d_S2.wav'
// import e2 from '../sounds/e2.wav'
// import f2 from '../sounds/f2.wav'
// import f_S2 from '../sounds/f_S2.wav'
// import g2 from '../sounds/g2.wav'
// import g_S2 from '../sounds/g_S2.wav'
// import a2 from '../sounds/a2.wav'
// import a_S2 from '../sounds/a_S2.wav'
// import b2 from '../sounds/b2.wav'
// import c3 from '../sounds/c3.wav'
// import c_S3 from '../sounds/c_S3.wav'
// import d3 from '../sounds/d3.wav'
// import d_S3 from '../sounds/d_S3.wav'
// import e3 from '../sounds/e3.wav'
// import f3 from '../sounds/f3.wav'
// import f_S3 from '../sounds/f_S3.wav'
// import g3 from '../sounds/g3.wav'
// import g_S3 from '../sounds/g_S3.wav'
// import a3 from '../sounds/a3.wav'
// import a_S3 from '../sounds/a_S3.wav'
// import b3 from '../sounds/b3.wav'
// import c4 from '../sounds/c4.wav'
// import c_S4 from '../sounds/c_S4.wav'
// import d4 from '../sounds/d4.wav'
// import d_S4 from '../sounds/d_S4.wav'
// import e4 from '../sounds/e4.wav'
// import f4 from '../sounds/f4.wav'
// import f_S4 from '../sounds/f_S4.wav'
// import g4 from '../sounds/g4.wav'
// import g_S4 from '../sounds/g_S4.wav'
// import a4 from '../sounds/a4.wav'
// import a_S4 from '../sounds/a_S4.wav'
// import b4 from '../sounds/b4.wav'
// import c5 from '../sounds/c5.wav'
// import c_S5 from '../sounds/c_S5.wav'
// import d5 from '../sounds/d5.wav'
// import d_S5 from '../sounds/d_S5.wav'
// import e5 from '../sounds/e5.wav'
// import f5 from '../sounds/f5.wav'
// import f_S5 from '../sounds/f_S5.wav'
// import g5 from '../sounds/g5.wav'
// import g_S5 from '../sounds/g_S5.wav'
// import a5 from '../sounds/a5.wav'
// import a_S5 from '../sounds/a_S5.wav'
// import b5 from '../sounds/b5.wav'

import c2 from '../sounds/wav/C2.wav'
import c_S2 from '../sounds/wav/CS2.wav'
import d2 from '../sounds/wav/D2.wav'
import d_S2 from '../sounds/wav/DS2.wav'
import e2 from '../sounds/wav/E2.wav'
import f2 from '../sounds/wav/F2.wav'
import f_S2 from '../sounds/wav/FS2.wav'
import g2 from '../sounds/wav/G2.wav'
import g_S2 from '../sounds/wav/GS2.wav'
import a2 from '../sounds/wav/A2.wav'
import a_S2 from '../sounds/wav/AS2.wav'
import b2 from '../sounds/wav/B2.wav'
import c3 from '../sounds/wav/C3.wav'
import c_S3 from '../sounds/wav/CS3.wav'
import d3 from '../sounds/wav/D3.wav'
import d_S3 from '../sounds/wav/DS3.wav'
import e3 from '../sounds/wav/E3.wav'
import f3 from '../sounds/wav/F3.wav'
import f_S3 from '../sounds/wav/FS3.wav'
import g3 from '../sounds/wav/G3.wav'
import g_S3 from '../sounds/wav/GS3.wav'
import a3 from '../sounds/wav/A3.wav'
import a_S3 from '../sounds/wav/AS3.wav'
import b3 from '../sounds/wav/B3.wav'
import c4 from '../sounds/wav/C4.wav'
import c_S4 from '../sounds/wav/CS4.wav'
import d4 from '../sounds/wav/D4.wav'
import d_S4 from '../sounds/wav/DS4.wav'
import e4 from '../sounds/wav/E4.wav'
import f4 from '../sounds/wav/F4.wav'
import f_S4 from '../sounds/wav/FS4.wav'
import g4 from '../sounds/wav/G4.wav'
import g_S4 from '../sounds/wav/GS4.wav'
import a4 from '../sounds/wav/A4.wav'
import a_S4 from '../sounds/wav/AS4.wav'
import b4 from '../sounds/wav/B4.wav'
import c5 from '../sounds/wav/C5.wav'
import c_S5 from '../sounds/wav/CS5.wav'
import d5 from '../sounds/wav/D5.wav'
import d_S5 from '../sounds/wav/DS5.wav'
import e5 from '../sounds/wav/E5.wav'
import f5 from '../sounds/wav/F5.wav'
import f_S5 from '../sounds/wav/FS5.wav'
import g5 from '../sounds/wav/G5.wav'
import g_S5 from '../sounds/wav/GS5.wav'
import a5 from '../sounds/wav/A5.wav'
import a_S5 from '../sounds/wav/AS5.wav'
import b5 from '../sounds/wav/B5.wav'


// import all notes from clarinet
// import c2_clarinet from '../sounds/clarinet/C2.wav';
// import c_S2_clarinet from '../sounds/clarinet/C#2.wav';
// import d2_clarinet from '../sounds/clarinet/D2.wav';
// import d_S2_clarinet from '../sounds/clarinet/D#2.wav';
// import e2_clarinet from '../sounds/clarinet/E2.wav';
// import f2_clarinet from '../sounds/clarinet/F2.wav';
// import f_S2_clarinet from '../sounds/clarinet/F#2.wav';
// import g2_clarinet from '../sounds/clarinet/G2.wav';
// import g_S2_clarinet from '../sounds/clarinet/G#2.wav';
// import a2_clarinet from '../sounds/clarinet/A2.wav';
// import a_S2_clarinet from '../sounds/clarinet/A#2.wav';
// import b2_clarinet from '../sounds/clarinet/B2.wav';
// import c3_clarinet from '../sounds/clarinet/C3.wav';
// import c_S3_clarinet from '../sounds/clarinet/C#3.wav';
// import d3_clarinet from '../sounds/clarinet/D3.wav';
// import d_S3_clarinet from '../sounds/clarinet/D#3.wav';
// import e3_clarinet from '../sounds/clarinet/E3.wav';
// import f3_clarinet from '../sounds/clarinet/F3.wav';
// import f_S3_clarinet from '../sounds/clarinet/F#3.wav';
import g3_clarinet from '../sounds/clarinet/G3.wav';
import g_S3_clarinet from '../sounds/clarinet/GS3.wav';
import a3_clarinet from '../sounds/clarinet/A3.wav';
import a_S3_clarinet from '../sounds/clarinet/AS3.wav';
import b3_clarinet from '../sounds/clarinet/B3.wav';
import c4_clarinet from '../sounds/clarinet/C4.wav';
import c_S4_clarinet from '../sounds/clarinet/CS4.wav';
import d4_clarinet from '../sounds/clarinet/D4.wav';
import d_S4_clarinet from '../sounds/clarinet/DS4.wav';
import e4_clarinet from '../sounds/clarinet/E4.wav';
import f4_clarinet from '../sounds/clarinet/F4.wav';
import f_S4_clarinet from '../sounds/clarinet/FS4.wav';
import g4_clarinet from '../sounds/clarinet/G4.wav';
import g_S4_clarinet from '../sounds/clarinet/GS4.wav';
import a4_clarinet from '../sounds/clarinet/A4.wav';
import a_S4_clarinet from '../sounds/clarinet/AS4.wav';
import b4_clarinet from '../sounds/clarinet/B4.wav';
import c5_clarinet from '../sounds/clarinet/C5.wav';
import c_S5_clarinet from '../sounds/clarinet/CS5.wav';
import d5_clarinet from '../sounds/clarinet/D5.wav';
import d_S5_clarinet from '../sounds/clarinet/DS5.wav';
import e5_clarinet from '../sounds/clarinet/E5.wav';
import f5_clarinet from '../sounds/clarinet/F5.wav';
import f_S5_clarinet from '../sounds/clarinet/FS5.wav';
import g5_clarinet from '../sounds/clarinet/G5.wav';
import g_S5_clarinet from '../sounds/clarinet/GS5.wav';
import a5_clarinet from '../sounds/clarinet/A5.wav';
import a_S5_clarinet from '../sounds/clarinet/AS5.wav';
import b5_clarinet from '../sounds/clarinet/B5.wav';

const note_list = [c2,c_S2,d2,d_S2,e2,f2,f_S2,g2,g_S2,a2,a_S2,b2,c3,c_S3,d3,d_S3,e3,f3,f_S3,g3,g_S3,a3,a_S3,b3,c4,c_S4,d4,d_S4,e4,f4,f_S4,g4,g_S4,a4,a_S4,b4,c5,c_S5,d5,d_S5,e5,f5,f_S5,g5,g_S5,a5,a_S5,b5]
const note_name_list = ["C2","C#2","D2","D#2","E2","F2","F#2","G2","G#2","A2","A#2","B2","C3","C#3","D3","D#3","E3","F3","F#3","G3","G#3","A3","A#3","B3","C4","C#4","D4","D#4","E4","F4","F#4","G4","G#4","A4","A#4","B4","C5","C#5","D5","D#5","E5","F5","F#5","G5","G#5","A5","A#5","B5"]

const note_list_clarinet = [g3_clarinet,g_S3_clarinet,a3_clarinet,a_S3_clarinet,b3_clarinet,c4_clarinet,c_S4_clarinet,d4_clarinet,d_S4_clarinet,e4_clarinet,f4_clarinet,f_S4_clarinet,g4_clarinet,g_S4_clarinet,a4_clarinet,a_S4_clarinet,b4_clarinet,c5_clarinet,c_S5_clarinet,d5_clarinet,d_S5_clarinet,e5_clarinet,f5_clarinet,f_S5_clarinet,g5_clarinet,g_S5_clarinet,a5_clarinet,a_S5_clarinet,b5_clarinet]
const note_name_list_clarinet = ["G3","G#3","A3","A#3","B3","C4","C#4","D4","D#4","E4","F4","F#4","G4","G#4","A4","A#4","B4","C5","C#5","D5","D#5","E5","F5","F#5","G5","G#5","A5","A#5","B5"]


const instruments = {
    Piano: {notes: note_list, note_names: note_name_list},
    Clarinet: {notes: note_list_clarinet, note_names: note_name_list_clarinet}
};

// const note_name_list = ['C3','C#3','D3','D#3','E3','F3','F#3','G3','G#3','A3','A#3','B3','C4','C#4','D4','D#4','E4','F4','F#4','G4','G#4','A4','A#4','B4','C5','C#5','D5','D#5','E5','F5','F#5','G5','G#5','A5','A#5','B5','C6','C#6','D6','D#6','E6','F6','F#6','G6','G#6','A6','A#6','B6']

// function sleep(ms) {
//     return new Promise(resolve => setTimeout(resolve, ms));
// }

//returns a random number between min and max (both included)
function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1) ) + min;
}

async function playInterval(note1, note2, delay) {
    const context = new (window.AudioContext || window.webkitAudioContext)()

    const [audio_file1, audio_file2] = await Promise.all([fetch(note1), fetch(note2)])

    const [buf1, buf2] = await Promise.all([audio_file1.arrayBuffer(), audio_file2.arrayBuffer()])

    const [audio_buf1, audio_buf2] = await Promise.all([context.decodeAudioData(buf1), context.decodeAudioData(buf2)])

    const source1 = context.createBufferSource()
    source1.connect(context.destination)
    source1.buffer = audio_buf1

    const source2 = context.createBufferSource()
    source2.connect(context.destination)
    source2.buffer = audio_buf2

    source1.start(context.currentTime)
    source2.start(context.currentTime + parseFloat(delay))
}

// async function playInterval(note1,note2,delay) {
//     const context = new (window.AudioContext || window.webkitAudioContext)()

//     var audio_file1 = await fetch(note1)
//     var audio_file2 = await fetch(note2)

//     var buf1 = await audio_file1.arrayBuffer()
//     var buf2 = await audio_file2.arrayBuffer()

//     let audio_buf1 = await context.decodeAudioData(buf1)
//     let audio_buf2 = await context.decodeAudioData(buf2)

//     const source1 = context.createBufferSource()
//     source1.connect(context.destination)
//     source1.buffer = audio_buf1

//     const source2 = context.createBufferSource()
//     source2.connect(context.destination)
//     source2.buffer = audio_buf2

//     source1.start(0)
//     source2.start(delay)
// }

async function playHarmonicInterval(note1, note2) {
    const context = new (window.AudioContext || window.webkitAudioContext)()

    const [audio_file1, audio_file2] = await Promise.all([fetch(note1), fetch(note2)])

    const [buf1, buf2] = await Promise.all([audio_file1.arrayBuffer(), audio_file2.arrayBuffer()])

    const [audio_buf1, audio_buf2] = await Promise.all([context.decodeAudioData(buf1), context.decodeAudioData(buf2)])

    const source1 = context.createBufferSource()
    source1.connect(context.destination)
    source1.buffer = audio_buf1

    const source2 = context.createBufferSource()
    source2.connect(context.destination)
    source2.buffer = audio_buf2

    let notes = [source1, source2]

    notes.forEach(note => {
        note.start(0)
    })
}

// async function playHarmonicInterval(note1,note2){
//     const context = new (window.AudioContext || window.webkitAudioContext)()

//     var audio_file1 = await fetch(note1)
//     var audio_file2 = await fetch(note2)

//     var buf1 = await audio_file1.arrayBuffer()
//     var buf2 = await audio_file2.arrayBuffer()

//     let audio_buf1 = await context.decodeAudioData(buf1)
//     let audio_buf2 = await context.decodeAudioData(buf2)

//     const source1 = context.createBufferSource()
//     source1.connect(context.destination)
//     source1.buffer = audio_buf1

//     const source2 = context.createBufferSource()
//     source2.connect(context.destination)
//     source2.buffer = audio_buf2

//     let notes = [source1,source2]

//     notes.forEach(note => {
//         note.start(0)
//     })
// }



// Functions built from playInterval and playHarmonicInterval

// ----- Melodic Intervals ----- //

// ----- Random Melodic Intervals ----- //

function playRandomAscendingUnison(note_range,delay,selectedInstrument){
    //select random note from note range (that has upper third avail)
    let random_num = getRndInteger(note_range[0],(note_range[1]))

    let first_note = instruments[selectedInstrument]['notes'][random_num]
    let second_note = instruments[selectedInstrument]['notes'][random_num]

    let first_note_name = instruments[selectedInstrument]['note_names'][random_num]
    let second_note_name = instruments[selectedInstrument]['note_names'][random_num]

    playInterval(first_note,second_note,delay)

    return [first_note_name,second_note_name]
}

function playRandomDescendingUnison(note_range,delay,selectedInstrument){
    //select random note from note range (that has upper third avail)
    let random_num = getRndInteger(note_range[0],(note_range[1]))

    let first_note = instruments[selectedInstrument]['notes'][random_num]
    let second_note = instruments[selectedInstrument]['notes'][random_num]

    let first_note_name = instruments[selectedInstrument]['note_names'][random_num]
    let second_note_name = instruments[selectedInstrument]['note_names'][random_num]

    playInterval(first_note,second_note,delay)

    return [first_note_name,second_note_name]
}

function playRandomAscendingThird(note_range,delay,selectedInstrument){
    //select random note from note range (that has upper third avail)
    let random_num = getRndInteger(note_range[0],(note_range[1]-4))

    let first_note = instruments[selectedInstrument]['notes'][random_num]
    let second_note = instruments[selectedInstrument]['notes'][random_num+4]

    let first_note_name = instruments[selectedInstrument]['note_names'][random_num]
    let second_note_name = instruments[selectedInstrument]['note_names'][random_num+4]

    playInterval(first_note,second_note,delay)

    return [first_note_name,second_note_name]
}

function playRandomDescendingThird(note_range,delay,selectedInstrument){
    //select random note from note range (that has upper third avail)
    let random_num = getRndInteger(note_range[0]+4,(note_range[1]))

    let first_note = instruments[selectedInstrument]['notes'][random_num]
    let second_note = instruments[selectedInstrument]['notes'][random_num-4]

    let first_note_name = instruments[selectedInstrument]['note_names'][random_num]
    let second_note_name = instruments[selectedInstrument]['note_names'][random_num-4]

    playInterval(first_note,second_note,delay)

    return [first_note_name,second_note_name]
}

function playRandomAscendingFourth(note_range,delay,selectedInstrument){
    //select random note from note range (that has upper third avail)
    let random_num = getRndInteger(note_range[0],(note_range[1]-5))

    let first_note = instruments[selectedInstrument]['notes'][random_num]
    let second_note = instruments[selectedInstrument]['notes'][random_num+5]

    let first_note_name = instruments[selectedInstrument]['note_names'][random_num]
    let second_note_name = instruments[selectedInstrument]['note_names'][random_num+5]

    playInterval(first_note,second_note,delay)

    return [first_note_name,second_note_name]
}

function playRandomDescendingFourth(note_range,delay,selectedInstrument){
    //select random note from note range (that has upper third avail)
    let random_num = getRndInteger(note_range[0]+5,(note_range[1]))

    let first_note = instruments[selectedInstrument]['notes'][random_num]
    let second_note = instruments[selectedInstrument]['notes'][random_num-5]

    let first_note_name = instruments[selectedInstrument]['note_names'][random_num]
    let second_note_name = instruments[selectedInstrument]['note_names'][random_num-5]

    playInterval(first_note,second_note,delay)

    return [first_note_name,second_note_name]
}

function playRandomAscendingFifth(note_range,delay,selectedInstrument){
    //select random note from note range (that has perfect 5th avail)
    let random_num = getRndInteger(note_range[0],(note_range[1]-7))

    let first_note = instruments[selectedInstrument]['notes'][random_num]
    let second_note = instruments[selectedInstrument]['notes'][random_num+7]

    let first_note_name = instruments[selectedInstrument]['note_names'][random_num]
    let second_note_name = instruments[selectedInstrument]['note_names'][random_num+7]

    playInterval(first_note,second_note,delay)

    return [first_note_name,second_note_name]
}

function playRandomDescendingFifth(note_range,delay,selectedInstrument){
    //select random note from note range (that has perfect 5th avail)
    let random_num = getRndInteger(note_range[0]+7,(note_range[1]))

    let first_note = instruments[selectedInstrument]['notes'][random_num]
    let second_note = instruments[selectedInstrument]['notes'][random_num-7]

    let first_note_name = instruments[selectedInstrument]['note_names'][random_num]
    let second_note_name = instruments[selectedInstrument]['note_names'][random_num-7]

    playInterval(first_note,second_note,delay)

    return [first_note_name,second_note_name]
}

function playRandomAscendingSixth(note_range,delay,selectedInstrument){
    //select random note from note range (that has major 6th avail)
    let random_num = getRndInteger(note_range[0],(note_range[1]-9))

    let first_note = instruments[selectedInstrument]['notes'][random_num]
    let second_note = instruments[selectedInstrument]['notes'][random_num+9]

    let first_note_name = instruments[selectedInstrument]['note_names'][random_num]
    let second_note_name = instruments[selectedInstrument]['note_names'][random_num+9]

    playInterval(first_note,second_note,delay)

    return [first_note_name,second_note_name]
}

function playRandomDescendingSixth(note_range,delay,selectedInstrument){
    //select random note from note range (that has major 6th avail)
    let random_num = getRndInteger(note_range[0]+9,(note_range[1]))

    let first_note = instruments[selectedInstrument]['notes'][random_num]
    let second_note = instruments[selectedInstrument]['notes'][random_num-9]

    let first_note_name = instruments[selectedInstrument]['note_names'][random_num]
    let second_note_name = instruments[selectedInstrument]['note_names'][random_num-9]

    playInterval(first_note,second_note,delay)

    return [first_note_name,second_note_name]
}

function playRandomAscendingMinorSeventh(note_range,delay,selectedInstrument){
    //select random note from note range (that has major 6th avail)
    let random_num = getRndInteger(note_range[0],(note_range[1]-10))

    let first_note = instruments[selectedInstrument]['notes'][random_num]
    let second_note = instruments[selectedInstrument]['notes'][random_num+10]

    let first_note_name = instruments[selectedInstrument]['note_names'][random_num]
    let second_note_name = instruments[selectedInstrument]['note_names'][random_num+10]

    playInterval(first_note,second_note,delay)

    return [first_note_name,second_note_name]
}

function playRandomAscendingOctave(note_range,delay,selectedInstrument){
    //select random note from note range (that has octave avail)
    let random_num = getRndInteger(note_range[0],(note_range[1]-12))

    let first_note = instruments[selectedInstrument]['notes'][random_num]
    let second_note = instruments[selectedInstrument]['notes'][random_num+12]

    let first_note_name = instruments[selectedInstrument]['note_names'][random_num]
    let second_note_name = instruments[selectedInstrument]['note_names'][random_num+12]

    playInterval(first_note,second_note,delay)

    return [first_note_name,second_note_name]
}


function playRandomDescendingOctave(note_range,delay,selectedInstrument){
    //select random note from note range (that has octave avail)
    let random_num = getRndInteger(note_range[0]+12,(note_range[1]))

    let first_note = instruments[selectedInstrument]['notes'][random_num]
    let second_note = instruments[selectedInstrument]['notes'][random_num-12]

    let first_note_name = instruments[selectedInstrument]['note_names'][random_num]
    let second_note_name = instruments[selectedInstrument]['note_names'][random_num-12]

    playInterval(first_note,second_note,delay)

    return [first_note_name,second_note_name]
}

// ----- Harmonic Intervals ----- //

function playRandomHarmonicThird(note_range){
    //select random note from note range (that has upper third avail)
    let random_num = getRndInteger(note_range[0],(note_range[1]-4))

    let first_note = note_list[random_num]
    let second_note = note_list[random_num+4]

    let first_note_name = note_name_list[random_num]
    let second_note_name = note_name_list[random_num+4]

    playHarmonicInterval(first_note,second_note)

    return [first_note_name,second_note_name]
}


function playRandomHarmonicFifth(note_range){
    //select random note from note range (that has perfect 5th avail)
    let random_num = getRndInteger(note_range[0],(note_range[1]-7))

    let first_note = note_list[random_num]
    let second_note = note_list[random_num+7]

    let first_note_name = note_name_list[random_num]
    let second_note_name = note_name_list[random_num+7]

    playHarmonicInterval(first_note,second_note)

    return [first_note_name,second_note_name]
}

function playRandomHarmonicMinorSeventh(note_range){
    //select random note from note range (that has perfect 5th avail)
    let random_num = getRndInteger(note_range[0],(note_range[1]-10))

    let first_note = note_list[random_num]
    let second_note = note_list[random_num+10]

    let first_note_name = note_name_list[random_num]
    let second_note_name = note_name_list[random_num+10]

    playHarmonicInterval(first_note,second_note)

    return [first_note_name,second_note_name]
}

function playRandomHarmonicOctave(note_range){
    //select random note from note range (that has octave avail)
    let random_num = getRndInteger(note_range[0],(note_range[1]-12))

    let first_note = note_list[random_num]
    let second_note = note_list[random_num+12]

    let first_note_name = note_name_list[random_num]
    let second_note_name = note_name_list[random_num+12]

    playHarmonicInterval(first_note,second_note)

    return [first_note_name,second_note_name]
}


// ---------- Exported Function ---------------- //


export function getNoteList(){
    return note_name_list
}

export async function playNote(note_name,selectedInstrument){
    // get index of note name
    let note_index =  instruments[selectedInstrument]['note_names'].findIndex(note => note === note_name)
    // get audio file import
    let note = instruments[selectedInstrument]['notes'][note_index]

    const context = new (window.AudioContext || window.webkitAudioContext)()

    const audio_file = await fetch(note)

    const buf = await audio_file.arrayBuffer()

    const audio_buf = await context.decodeAudioData(buf)

    const source = context.createBufferSource()
    source.connect(context.destination)
    source.buffer = audio_buf

    source.start(0)
}

export function playIntervalFromNames(note1_name,note2_name,delay,selectedInstrument){
    let first_note_index = instruments[selectedInstrument]['note_names'].findIndex(note => note === note1_name)
    let second_note_index = instruments[selectedInstrument]['note_names'].findIndex(note => note === note2_name)

    let first_note = instruments[selectedInstrument]['notes'][first_note_index]
    let second_note = instruments[selectedInstrument]['notes'][second_note_index]

    playInterval(first_note,second_note,delay)
}

export function playHarmonicIntervalFromNames(note1_name,note2_name){
    let first_note_index = note_name_list.findIndex(note => note === note1_name)
    let second_note_index = note_name_list.findIndex(note => note === note2_name)

    let first_note = note_list[first_note_index]
    let second_note = note_list[second_note_index]

    playHarmonicInterval(first_note,second_note)

    return [note1_name,note2_name]
}

export function playRandomInterval(available_intervals,note_range,delay,selectedInstrument){
    // let available_intervals = ['major_third','perfect_fifth','octave']

    let random_num = Math.floor(Math.random() * available_intervals.length);
    let selected_interval = available_intervals[random_num]
    
    let [first_note_name,second_note_name] = ['','']

    if(selected_interval === 'ascending_unison'){
        [first_note_name,second_note_name] = playRandomAscendingUnison(note_range,delay,selectedInstrument)
    }
    else if(selected_interval === 'descending_unison'){
        [first_note_name,second_note_name] = playRandomDescendingUnison(note_range,delay,selectedInstrument)
    }
    else if(selected_interval === 'ascending_major_third'){
        [first_note_name,second_note_name] = playRandomAscendingThird(note_range,delay,selectedInstrument)
    }
    else if(selected_interval === 'descending_major_third'){
        [first_note_name,second_note_name] = playRandomDescendingThird(note_range,delay,selectedInstrument)
    }
    else if(selected_interval === 'ascending_perfect_fourth'){
        [first_note_name,second_note_name] = playRandomAscendingFourth(note_range,delay,selectedInstrument)
    }
    else if(selected_interval === 'descending_perfect_fourth'){
        [first_note_name,second_note_name] = playRandomDescendingFourth(note_range,delay,selectedInstrument)
    }
    else if(selected_interval === 'ascending_perfect_fifth'){
        [first_note_name,second_note_name] = playRandomAscendingFifth(note_range,delay,selectedInstrument)
    }
    else if(selected_interval === 'descending_perfect_fifth'){
        [first_note_name,second_note_name] = playRandomDescendingFifth(note_range,delay,selectedInstrument)
    }
    else if(selected_interval === 'ascending_major_sixth'){
        [first_note_name,second_note_name] = playRandomAscendingSixth(note_range,delay,selectedInstrument)
    }
    else if(selected_interval === 'descending_major_sixth'){
        [first_note_name,second_note_name] = playRandomDescendingSixth(note_range,delay,selectedInstrument)
    }
    else if(selected_interval === 'ascending_minor_seventh'){
        [first_note_name,second_note_name] = playRandomAscendingMinorSeventh(note_range,delay,selectedInstrument)
    }
    // else if(selected_interval === 'descending_minor_seventh'){
    //     [first_note_name,second_note_name] = playRandomDescendingMinorSeventh(note_range,delay,selectedInstrument)
    // }
    else if(selected_interval === 'ascending_octave'){
        [first_note_name,second_note_name] = playRandomAscendingOctave(note_range,delay,selectedInstrument)
    }
    else if(selected_interval === 'descending_octave'){
        [first_note_name,second_note_name] = playRandomDescendingOctave(note_range,delay,selectedInstrument)
    }

    return {selected_interval,notes:[first_note_name,second_note_name]}

}

export function playRandomHarmonicInterval(available_intervals,note_range){
    let random_num = Math.floor(Math.random() * available_intervals.length);
    let selected_interval = available_intervals[random_num]
    
    let [first_note_name,second_note_name] = ['','']

    if(selected_interval === 'major_third'){
        [first_note_name,second_note_name] = playRandomHarmonicThird(note_range)
        // selected_interval = 'third'
    }
    // else if(selected_interval === 'perfect_fourth'){
    //     [first_note_name,second_note_name] = playRandomFourth(note_range,delay)
    //     // selected_interval = 'fifth'
    // }
    else if(selected_interval === 'perfect_fifth'){
        [first_note_name,second_note_name] = playRandomHarmonicFifth(note_range)
        // selected_interval = 'fifth'
    }
    // else if(selected_interval === 'major_sixth'){
    //     [first_note_name,second_note_name] = playRandomSixth(note_range,delay)
    //     // selected_interval = 'fifth'
    // }
    else if(selected_interval === 'minor_seventh'){
        [first_note_name,second_note_name] = playRandomHarmonicMinorSeventh(note_range)
    }
    else if(selected_interval === 'octave'){
        [first_note_name,second_note_name] = playRandomHarmonicOctave(note_range)
        // selected_interval = 'fifth'
    }

    return {selected_interval,notes:[first_note_name,second_note_name]}

}

export async function playTest(note1_name,note2_name){
    let first_note_index = note_name_list.findIndex(note => note === note1_name)
    let second_note_index = note_name_list.findIndex(note => note === note2_name)

    let first_note = note_list[first_note_index]
    let second_note = note_list[second_note_index]

    console.log(first_note,second_note)
    console.log(c2,c3)
    playHarmonicInterval(first_note,second_note)
    // playInterval(c2,c2,1)
}

// External Functions for playing intervals with specific notes//

export function playAscendingUnison(first_note,delay){
    playInterval(first_note,first_note,delay)

    return [first_note,first_note]
}

export function playDescendingUnison(first_note,delay){
    playInterval(first_note,first_note,delay)

    return [first_note,first_note]
}

export function playAscendingThird(first_note_name,delay){
    let first_note_index = note_name_list.findIndex(note => note === first_note_name)
    let second_note_index = first_note_index + 4

    let first_note = note_list[first_note_index]
    let second_note = note_list[second_note_index]

    playInterval(first_note,second_note,delay)

    return [first_note_name,note_name_list[second_note_index]]
}

export function playDescendingThird(first_note_name,delay){
    let first_note_index = note_name_list.findIndex(note => note === first_note_name)
    let second_note_index = first_note_index - 4

    let first_note = note_list[first_note_index]
    let second_note = note_list[second_note_index]

    playInterval(first_note,second_note,delay)

    return [first_note_name,note_name_list[second_note_index]]
}

export function playAscendingFourth(first_note_name,delay){
    let first_note_index = note_name_list.findIndex(note => note === first_note_name)
    let second_note_index = first_note_index + 5

    let first_note = note_list[first_note_index]
    let second_note = note_list[second_note_index]

    playInterval(first_note,second_note,delay)

    return [first_note_name,note_name_list[second_note_index]]
}

export function playDescendingFourth(first_note_name,delay){
    let first_note_index = note_name_list.findIndex(note => note === first_note_name)
    let second_note_index = first_note_index - 5

    let first_note = note_list[first_note_index]
    let second_note = note_list[second_note_index]

    playInterval(first_note,second_note,delay)

    return [first_note_name,note_name_list[second_note_index]]
}

export function playAscendingFifth(first_note_name,delay){
    let first_note_index = note_name_list.findIndex(note => note === first_note_name)
    let second_note_index = first_note_index + 7

    let first_note = note_list[first_note_index]
    let second_note = note_list[second_note_index]

    playInterval(first_note,second_note,delay)

    return [first_note_name,note_name_list[second_note_index]]
}

export function playDescendingFifth(first_note_name,delay){
    let first_note_index = note_name_list.findIndex(note => note === first_note_name)
    let second_note_index = first_note_index - 7

    let first_note = note_list[first_note_index]
    let second_note = note_list[second_note_index]

    playInterval(first_note,second_note,delay)

    return [first_note_name,note_name_list[second_note_index]]
}

export function playAscendingSixth(first_note_name,delay){
    let first_note_index = note_name_list.findIndex(note => note === first_note_name)
    let second_note_index = first_note_index + 9

    let first_note = note_list[first_note_index]
    let second_note = note_list[second_note_index]

    playInterval(first_note,second_note,delay)

    return [first_note_name,note_name_list[second_note_index]]
}

export function playDescendingSixth(first_note_name,delay){
    let first_note_index = note_name_list.findIndex(note => note === first_note_name)
    let second_note_index = first_note_index - 9

    let first_note = note_list[first_note_index]
    let second_note = note_list[second_note_index]

    playInterval(first_note,second_note,delay)

    return [note_name_list[second_note_index],first_note_name]
}

export function playAscendingMinorSeventh(first_note_name,delay){
    let first_note_index = note_name_list.findIndex(note => note === first_note_name)
    let second_note_index = first_note_index + 10

    let first_note = note_list[first_note_index]
    let second_note = note_list[second_note_index]

    playInterval(first_note,second_note,delay)

    return [first_note_name,note_name_list[second_note_index]]
}


export function playAscendingOctave(first_note_name,delay){
    let first_note_index = note_name_list.findIndex(note => note === first_note_name)
    let second_note_index = first_note_index + 12

    let first_note = note_list[first_note_index]
    let second_note = note_list[second_note_index]

    playInterval(first_note,second_note,delay)

    return [first_note_name,note_name_list[second_note_index]]
}

export function playDescendingOctave(first_note_name,delay){
    let first_note_index = note_name_list.findIndex(note => note === first_note_name)
    let second_note_index = first_note_index - 12

    let first_note = note_list[first_note_index]
    let second_note = note_list[second_note_index]

    playInterval(first_note,second_note,delay)

    return [note_name_list[second_note_index],first_note_name]
}

//  Harmonic Exported Functions
export function playHarmonicUnison(first_note){
    playHarmonicInterval(first_note,first_note)

    console.log('yo',first_note)

    return [first_note,first_note]
}

export function playHarmonicThird(first_note_name){
    let first_note_index = note_name_list.findIndex(note => note === first_note_name)
    let second_note_index = first_note_index + 4

    let first_note = note_list[first_note_index]
    let second_note = note_list[second_note_index]

    playHarmonicInterval(first_note,second_note)

    return [first_note_name,note_name_list[second_note_index]]
}

export function playHarmonicFourth(first_note_name){
    let first_note_index = note_name_list.findIndex(note => note === first_note_name)
    let second_note_index = first_note_index + 5

    let first_note = note_list[first_note_index]
    let second_note = note_list[second_note_index]

    playHarmonicInterval(first_note,second_note)

    return [first_note_name,note_name_list[second_note_index]]
}

export function playHarmonicFifth(first_note_name){
    let first_note_index = note_name_list.findIndex(note => note === first_note_name)
    let second_note_index = first_note_index + 7

    let first_note = note_list[first_note_index]
    let second_note = note_list[second_note_index]

    playHarmonicInterval(first_note,second_note)

    return [first_note_name,note_name_list[second_note_index]]
}

export function playHarmonicSixth(first_note_name){
    let first_note_index = note_name_list.findIndex(note => note === first_note_name)
    let second_note_index = first_note_index + 9

    let first_note = note_list[first_note_index]
    let second_note = note_list[second_note_index]

    playHarmonicInterval(first_note,second_note)

    return [first_note_name,note_name_list[second_note_index]]
}

export function playHarmonicMinorSeventh(first_note_name){
    let first_note_index = note_name_list.findIndex(note => note === first_note_name)
    let second_note_index = first_note_index + 10

    let first_note = note_list[first_note_index]
    let second_note = note_list[second_note_index]

    playHarmonicInterval(first_note,second_note)

    return [first_note_name,note_name_list[second_note_index]]
}

export function playHarmonicOctave(first_note_name){
    let first_note_index = note_name_list.findIndex(note => note === first_note_name)
    let second_note_index = first_note_index + 12

    let first_note = note_list[first_note_index]
    let second_note = note_list[second_note_index]

    playHarmonicInterval(first_note,second_note)

    return [first_note_name,note_name_list[second_note_index]]
}