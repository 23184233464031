import React from "react";
import { Link } from "react-router-dom";

import '../css/Home.css';

const Home = (props) => {
    return(
        // <React.Fragment>
        <div className="home-container">
            <h1>Ear Trainer App</h1>

            <h4>This application contains a set of games designed to help musically train your ear.</h4>

            <div className="game-container">
                <Link className="game-title" to="interval">Interval Game</Link>
                <p className="game-description">Hear 2 notes and identify the played interval.</p>
            </div>
            <div className="game-container">
                <Link className="game-title" to="harmonic-interval">Harmonic Interval Game</Link>
                <p className="game-description">Hear 2 notes played simultaneously and identify the played harmonic interval.</p>
            </div>

            <div className="game-container">
                <Link className="game-title" to="interval-comparison">Interval Comparison Game</Link>
                <p className="game-description">Hear 2 to 3 different intervals and select the lagerst/smallest interval.</p>
            </div>
        {/* </React.Fragment> */}
        </div>

    );
}

export default Home;