import React from "react";

const GuessView = (props) => {
    return (
        <React.Fragment>
            <div className='guess-btn-group'>
                <div>
                    <label>Ascending</label>
                    {props.selectedIntervals['ascending_unison'] ? <button className='default-button' onClick={() => props.handleGuess('ascending_unison')}>Unison</button> : null}
                    {props.selectedIntervals['ascending_major_third'] ? <button className='default-button' onClick={() => props.handleGuess('ascending_major_third')}>Major Third</button> : null}
                    {props.selectedIntervals['ascending_perfect_fourth'] ? <button className='default-button' onClick={() => props.handleGuess('ascending_perfect_fourth')}>Perfect Fourth</button> : null}
                    {props.selectedIntervals['ascending_perfect_fifth'] ? <button className='default-button' onClick={() => props.handleGuess('ascending_perfect_fifth')}>Perfect Fifth</button> : null}
                    {props.selectedIntervals['ascending_major_sixth'] ? <button className='default-button' onClick={() => props.handleGuess('ascending_major_sixth')}>Major Sixth</button> : null}
                    {props.selectedIntervals['ascending_minor_seventh'] ? <button className='default-button' onClick={() => props.handleGuess('ascending_minor_seventh')}>Minor Seventh</button> : null}
                    {props.selectedIntervals['ascending_octave'] ? <button className='default-button' onClick={() => props.handleGuess('ascending_octave')}>Octave</button> : null}
                </div>
                <div>
                    <label>Descending</label>
                    {props.selectedIntervals['descending_unison'] ? <button className='default-button' onClick={() => props.handleGuess('descending_unison')}>Unison</button> : null}
                    {props.selectedIntervals['descending_major_third'] ? <button className='default-button' onClick={() => props.handleGuess('descending_major_third')}>Major Third</button> : null}
                    {props.selectedIntervals['descending_perfect_fourth'] ? <button className='default-button' onClick={() => props.handleGuess('descending_perfect_fourth')}>Perfect Fourth</button> : null}
                    {props.selectedIntervals['descending_perfect_fifth'] ? <button className='default-button' onClick={() => props.handleGuess('descending_perfect_fifth')}>Perfect Fifth</button> : null}
                    {props.selectedIntervals['descending_major_sixth'] ? <button className='default-button' onClick={() => props.handleGuess('descending_major_sixth')}>Major Sixth</button> : null}
                    {props.selectedIntervals['descending_minor_seventh'] ? <button className='default-button' onClick={() => props.handleGuess('descending_minor_seventh')}>Minor Seventh</button> : null}
                    {props.selectedIntervals['descending_octave'] ? <button className='default-button' onClick={() => props.handleGuess('descending_octave')}>Octave</button> : null}
                </div>
            </div>
            <div>
                <button className='default-button' onClick={props.handleReplay}>Replay</button>
            </div>
        </React.Fragment>
    )
}

export default GuessView;