import React, { useEffect, useState } from 'react';
import Select from 'react-select'


import {getNoteList, playRandomHarmonicInterval, playHarmonicIntervalFromNames} from '../lib/Sounds'
import Vex from 'vexflow'

import SettingsController from '../components/pages/HarmonicIntervalGame/SettingsController'
import FeedbackIntervals from '../components/pages/HarmonicIntervalGame/FeedbackIntervals';

import styles from '../css/HarmonicIntervalGame.module.css';

const note_name_list = getNoteList()

const HarmonicIntervalGame = (props) => {
    const [gameState, setGameState] = useState('not_played')
    const [answer, setAnswer] = useState()
    const [numCorrect, setNumCorrect] = useState(0)
    const [numIncorrect, setNumIncorrect] = useState(0)
    const [minNote, setMinNote] = useState(0)
    const [maxNote, setMaxNote] = useState(note_name_list.length-1)


    let selected_intervals_initial_obj = {
        'major_third': true,
        'perfect_fifth': true,
        'minor_seventh': true,
        'octave': true
    }
    const [selectedIntervals, setSelectedIntervals] = useState(selected_intervals_initial_obj)


    const instrument_list = [
        'Piano',
        'Clarinet'
    ]
    const [selectedInstrument, setSelectedInstrument] = useState(instrument_list[0]);

    function handlePlayButton(){
        //clear music staff
        const staff = document.getElementById('music-staff');
        while (staff.hasChildNodes()) {
            staff.removeChild(staff.lastChild);
        }

        //format selected interval obj into array
        let selected_interval_array = []
        Object.keys(selectedIntervals).map(key =>{
            if(selectedIntervals[key]){
                selected_interval_array.push(key)
            }
        })

        //play random interval
        let solution = playRandomHarmonicInterval(selected_interval_array,[minNote,maxNote])
        //update state
        setAnswer(solution)
        setGameState('played')
    }

    function handleThirdGuess(){
        console.log(answer)
        if(answer.selected_interval === 'major_third'){
            // alert('Correct')
            setNumCorrect(numCorrect+1)
            setGameState('correct')
        }
        else{
            // alert('Incorrect')
            setNumIncorrect(numIncorrect+1)
            setGameState('incorrect')
        }
        drawMusicStaff(answer.notes[0],answer.notes[1])
    }

    function handleFifthGuess(){
        console.log(answer)
        if(answer.selected_interval === 'perfect_fifth'){
            // alert('Correct')
            setNumCorrect(numCorrect+1)
            setGameState('correct')
        }
        else{
            // alert('Incorrect')
            setNumIncorrect(numIncorrect+1)
            setGameState('incorrect')
        }
        drawMusicStaff(answer.notes[0],answer.notes[1])
    }

    function handleMinorSeventhGuess(){
        console.log(answer)
        if(answer.selected_interval === 'minor_seventh'){
            // alert('Correct')
            setNumCorrect(numCorrect+1)
            setGameState('correct')
        }
        else{
            // alert('Incorrect')
            setNumIncorrect(numIncorrect+1)
            setGameState('incorrect')
        }
        drawMusicStaff(answer.notes[0],answer.notes[1])
    }

    function handleOctaveGuess(){
        console.log(answer)
        if(answer.selected_interval === 'octave'){
            // alert('Correct')
            setNumCorrect(numCorrect+1)
            setGameState('correct')
        }
        else{
            // alert('Incorrect')
            setNumIncorrect(numIncorrect+1)
            setGameState('incorrect')
        }
        drawMusicStaff(answer.notes[0],answer.notes[1])
    }

    function handleReplay(){
        // console.log('nn',answer.notes)
        playHarmonicIntervalFromNames(answer.notes[0],answer.notes[1])
    }

    function handleClickIntervalCheckbox(event){
        //make copy of selected intervals obj
        let copy_selectedIntervals = {...selectedIntervals}
        //update state
        copy_selectedIntervals[event.target.value] = event.target.checked
        setSelectedIntervals(copy_selectedIntervals)
    }

    function drawMusicStaff(note1,note2){
        const staff = document.getElementById('music-staff');
        while (staff.hasChildNodes()) {
            staff.removeChild(staff.lastChild);
        }
        // Create an SVG renderer and attach it to the DIV element named "boo".
        var vf = new Vex.Flow.Factory({renderer: {elementId: 'music-staff'}});
        var score = vf.EasyScore();
        var system = vf.System();

        system.addStave({
            voices: [score.voice(score.notes(`${note1}/h, ${note2}`))]
        }).addClef('treble').addTimeSignature('4/4');
        
        vf.draw();
    }

    // useEffect(()=>{
    //     drawMusicStaff('C4','C#4')
    // }, [])

    // const options = [
    //     { value: 'chocolate', label: 'Chocolate' },
    //     { value: 'strawberry', label: 'Strawberry' },
    //     { value: 'vanilla', label: 'Vanilla' }
    // ]


    let option_list = []
    for(let i in note_name_list){
        option_list.push({value: i,label: note_name_list[i]})
    }


    return (
        <div className={styles.harmonic_interval_game}>
            <h1>Harmonic Interval Game</h1>
            <div className={styles.header_section}>
                <div className={styles.scoreboard}>
                    <label>Correct:</label>
                    <p>{numCorrect}</p>
                    <label>Incorrect:</label>
                    <p>{numIncorrect}</p>
                </div>
                {/* <div className='interval-selector'>
                    <input type="checkbox" onChange={handleClickIntervalCheckbox} value="major_third" checked={selectedIntervals['major_third']} disabled={(gameState === 'played')} />
                    <label>Major Third</label>
                    <br />
                    <input type="checkbox" onChange={handleClickIntervalCheckbox} value="perfect_fifth" checked={selectedIntervals['perfect_fifth']} disabled={(gameState === 'played')} />
                    <label>Perfect Fifth</label>
                    <br />
                    <input type="checkbox" onChange={handleClickIntervalCheckbox} value="octave" checked={selectedIntervals['octave']} disabled={(gameState === 'played')} />
                    <label>Octave</label>

                    <br/>
                    <br/>

                    <label>Min Note</label>
                    <Select className="min-note-select" options={option_list} defaultValue={option_list[0]} onChange={(selected_option) => { setMinNote(selected_option.value) }}/>
                    <label>Max Note</label>
                    <Select className="max-note-select" options={option_list} defaultValue={option_list[option_list.length-1]} onChange={(selected_option) => { setMaxNote(selected_option.value) }}/>
                </div> */}
                <SettingsController selectedIntervals={selectedIntervals} setSelectedIntervals={setSelectedIntervals} gameState={gameState} setMinNote={setMinNote} setMaxNote={setMaxNote} instrument_list={instrument_list} setSelectedInstrument={setSelectedInstrument}/>
            </div>


            {gameState === 'correct' ?
                <React.Fragment>
                    <h2> Correct! </h2>
                    <p>{answer.selected_interval}</p> 
                    <p>{answer.notes[0]},{answer.notes[1]}</p>
                </React.Fragment>
                : null
            }
            {gameState === 'incorrect' ?
                <React.Fragment>
                    <h2> Incorrect </h2>
                    <p>{answer.selected_interval}</p> 
                    <p>{answer.notes[0]},{answer.notes[1]}</p> 
                </React.Fragment>
                : null
            }

            <div id='music-staff' className={styles.music_staff}></div>



            {gameState === 'played'? 
                <React.Fragment>
                    <div className={styles.guess_btn_group}>
                        {selectedIntervals['major_third'] ? <button className={styles.default_button} onClick={handleThirdGuess}>Major Third</button> : null}
                        {selectedIntervals['perfect_fifth'] ? <button className={styles.default_button} onClick={handleFifthGuess}>Perfect Fifth</button> : null}
                        {selectedIntervals['minor_seventh'] ? <button className={styles.default_button} onClick={handleMinorSeventhGuess}>Minor Seventh</button> : null}
                        {selectedIntervals['octave'] ? <button className={styles.default_button} onClick={handleOctaveGuess}>Octave</button> : null}
                    </div>
                    <div>
                        <button onClick={handleReplay}>Replay</button>
                    </div>
                </React.Fragment>
                : 
                <button className={styles.play_button} onClick={handlePlayButton}>Play</button>
            }

            {(gameState === 'correct' || gameState === 'incorrect') && (
                <FeedbackIntervals firstNote={answer.notes[0]} secondNote={answer.notes[1]} selectedInstrument={selectedInstrument}/>
            )}

        </div>
    );
}

export default HarmonicIntervalGame;